<template>
<div class="distribution-container">
  <div class="card-list-wrapper">
     <news-detail :is-edit="true" />
  </div>
</div>
 
</template>

<script>
import NewsDetail from "./components/newsDetail";

export default {
  name: "EditForm",
  components: { NewsDetail },
};
</script>

<style lang="less" scoped>
.distribution-container {
  width: 100%;
  background-color: #f9fbfc;
  padding: 24px 0;
  min-height: calc(100vh - 440px);
  .card-list-wrapper {
    background-color: #ffffff;
    max-width: 1200px;
    margin: 0px auto;
    min-height: 600px;
  }
}
</style>